<template>
	<v-container>
    <v-chip color="primary">{{ auth.profile.tier.name }}</v-chip>
		<v-row>
			<h1 class="pa-5" style="font-weight: 300;">Welcome, {{ auth.profile.name }}.</h1>
		</v-row>
		<v-row>
			<v-col cols="6">
        <v-card>
          <div id="embed-api-auth-container" ref="authContainer"></div>
          <div id="chart-container"></div>
          <div id="view-selector-container"></div>
        </v-card>
				<v-card>
					<v-card-title>Quick Access</v-card-title>
					<v-container>
						<v-row>
							<v-col cols="6">
								<v-card color="grey">
									<v-card-title>Create a Pack</v-card-title>
									<v-card-text>
										Create a new pack from scratch using the tool.
									</v-card-text>
									<v-card-actions>
										<v-btn text to="/manage/packs/new">
										Go <v-icon>keyboard_arrow_right</v-icon></v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
							<v-col cols="6">
								<v-card color="grey">
									<v-card-title>Manage Portal Content</v-card-title>
									<v-card-text>
										Manage Content for portal
									</v-card-text>
									<v-card-actions>
										<v-btn text to="/portal/content">
										Go <v-icon>keyboard_arrow_right</v-icon></v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<v-card color="grey">
									<v-card-title>Manage Your Account</v-card-title>
									<v-card-text>
										Manage Admin User account settings
									</v-card-text>
									<v-card-actions>
										<v-btn text to="/settings">Go <v-icon>keyboard_arrow_right</v-icon></v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
			<v-col cols="6">
				<v-card>
					<v-card-title>Overview</v-card-title>
					<v-card-text>
						Users: {{ 0 }}
					</v-card-text>
          <v-card-text>
						Packs: {{ 0 }}
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script lang="js">
import Vue from 'vue';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['auth'])
  },
  mounted() {
    const { authContainer } = this.$refs;

    console.log(this.$refs.authContainer);

    const { gapi } = window;

    gapi.analytics.ready(() => {
      gapi.analytics.auth.authorize({
        container: authContainer,
        clientid: 'clientid'
      });
    });
  },
};
</script>
